<template>
  <div class="main-content">
    <div class="video-wrap">
      <video
        id="video"
        src="../assets/videos/banner.mp4"
        autoplay
        loop
        muted
        x5-video-player-fullscreen="true"
        x5-video-player-type="h5"
        playsinline="true"
        webkit-playsinline="true"
      ></video>
      <video
        id="video-mobile"
        src="../assets/videos/banner-mobile.mp4"
        autoplay
        loop
        muted
        x5-video-player-fullscreen="true"
        x5-video-player-type="h5"
        playsinline="true"
        webkit-playsinline="true"
      ></video>
      <!-- x5-playsinline="true" -->
      <div class="title-desc">
        <div class="title">
          {{ messages.mainContent.video.title }}
        </div>
        <div class="desc">{{ messages.mainContent.video.desc }}</div>
      </div>
    </div>
    <div class="meelons-wrap">
      <div class="meelons-wrap-title">
        {{ messages.mainContent.meelons.title }}
      </div>
      <div class="meelons-wrap-list">
        <div
          class="meelons-wrap-list-item"
          v-for="(item, index) in messages.mainContent.meelons.list"
          :key="index"
          :class="{ bgblue: index === 1, bgyellow: index === 2 }"
        >
          <img :src="item.icon" />
          <span>{{ item.title }}</span>
          <span>{{ item.desc }}</span>
        </div>
      </div>
      <div class="meelons-wrap-mint-rank" ref="mintRank">
        <div class="header">
          <span :class="{ active: isShowMint }" @click="mintView">
            {{ messages.mint }}
          </span>
          <span :class="{ active: !isShowMint }" @click="rankView">
            {{ messages.rank }}
          </span>
        </div>
        <template v-if="remainTime <= 0">
          <!-- <template v-if="true"> -->
          <div class="content">
            <div class="input-box">
              <input
                type="text"
                :placeholder="placeholderText"
                v-model="address"
              />
            </div>
            <span @click="check">{{ messages.check }}</span>
            <span
              :class="{
                active: checkStatus && !isAlreadyMint,
              }"
              @click="mint"
              v-show="isShowMint"
              >{{ mintText }}</span
            >
          </div>
          <div class="rank-result" v-if="checkRankStatus">
            <div class="rank-result-box">
              <!-- <img src="../assets/images/common/icon1.png" /> -->
              <img :src="rankData.avatar" />
              <div class="detail">
                <span>{{
                  messages.mainContent.meelons.rankResult.meelon +
                  ' ' +
                  rankData.meelon
                }}</span>
                <span>{{ messages.mainContent.meelons.rankResult.rank }}</span>
                <div>{{ rankData.rank }}</div>
              </div>
            </div>
          </div>
          <div v-else-if="!isShowMint && !checkRankStatus" class="default-rank">
            {{ messages.mainContent.meelons.rankResult.noShowNft }}
          </div>
          <div
            class="tips massage-tips"
            v-show="isAlreadyMint || !isCorrectAddress"
          >
            {{ warningTips }}
          </div>
          <div class="tips massage-tips" v-show="isTokenId">
            {{ messages.mainContent.meelons.rankResult.tokenIdTips }}
          </div>
          <div class="tips" v-show="!isTokenId && isClickMint">
            {{ tips }}
            <a
              class="tips"
              style="color: #2980fe"
              v-if="checkStatus"
              :href="checkResultUrl"
              >view the transaction in details</a
            >
          </div>
          <a
            href="https://creativecommons.org/choose/zero/?lang=en"
            v-show="!isTokenId"
          >
            <div class="tips protocol">
              {{ messages.mainContent.meelons.rankResult.protocol }}
            </div>
          </a>
        </template>
        <template v-else>
          <div class="timer">
            <TimerBox
              :time="messages.mainContent.meelons.timer.hours"
              :remainTime="String(hours)"
            />
            <div class="circle-content">
              <div class="circle" v-for="c in 2" :key="c"></div>
            </div>
            <TimerBox
              :time="messages.mainContent.meelons.timer.mins"
              :remainTime="String(mins)"
            />
            <div class="circle-content">
              <div class="circle" v-for="c in 2" :key="c"></div>
            </div>
            <TimerBox
              :time="messages.mainContent.meelons.timer.second"
              :remainTime="String(second)"
            />
          </div>
        </template>
      </div>
      <Rarities :messages="messages" />
      <Attributes :messages="messages" />
    </div>
  </div>
</template>

<script>
import Attributes from './Attributes.vue'
import Rarities from './Rarities.vue'
import {
  checkWhiteList,
  checkRank,
  buildMint,
  contractAddress,
} from '../utils/UseDemo'
import { checkClinetModel } from '../utils/index'
import { ethers } from 'ethers'
import TimerBox from './TimerBox.vue'
const MeelonNFT = {
  one: 'QmSnksUuxcG5gDRDSGLyGBQbLg6WaosnHAuiwmanU3DdgX',
  two: 'QmRcwBAmLaS11x4cMzAKTzVZXM9zAkNeeKtE4FBZoupu8K',
  three: 'QmeRWaiMU3dbNxV9p6RNKsSjfENt1zQZetfx8peUurnMTW',
  four: 'Qma7VF7X7kbn5KFmWD1Brc5nAxE5pkvEbJCSNB3KLVgPum',
  five: 'QmdnkLCJ3LXtQrLoQrLVisbEmmUu3enEpLcWgtk7c4juvo',
  six: 'QmZUFst72SS6i7oBNPQFx9WUr4xGJ3u6Q5uHc9CWELa6at',
  seven: 'QmTkKqyzKMNKDtnXmcgvwzv7Ly5UiAL2NYmCEG9EBVi3Lc',
  eight: 'QmRzWMkndqBCrqJA5Q6yxuqTpYvTR7kj3eYSHzrWqroA3Y',
  nine: 'QmRPPPxRJZxCEat77z3HqdveqpsQUzC22HnV1iSBbGQQRV',
  ten: 'QmYYXpDp8nR1Ak3AXdpFKmk1Nvgze2VU3X5VQg9S4eemED',
}
const ABI = [
  'function mintMeelon(bytes32[][] memory proof, uint[] memory whichRoot) public returns (uint256 tokenId)',
  'function queryMintAllow(address account, uint[] memory whichList) public view returns (bool[] memory result)',
]
export default {
  name: 'MainContent',
  components: {
    Rarities,
    Attributes,
    TimerBox,
  },
  props: {
    messages: {
      type: Object,
    },
    account: {
      type: String,
    },
    getPaccount: {
      type: Function,
    },
  },
  data() {
    return {
      isShowMint: true,
      isClickMint: false,
      isAlreadyMint: false,
      isCorrectAddress: true,
      isTokenId: false,
      checkStatus: false,
      checkRankStatus: false,
      addressData: [],
      address: '',
      checkNum: '',
      placeholder: 'Enter your address to check if you are qualified',
      rankData: {},
      contract: {},
      provider: {},
      hours: '00',
      mins: '00',
      second: '00',
      remainTime: 0,
      checkResultUrl: 'https://etherscan.io/tx/',
    }
  },
  mounted() {
    // 获取 web3 对象
    this.provider = new ethers.providers.Web3Provider(window.ethereum)
    this.contract = new ethers.Contract(
      '0xdd1c843a0e960724aa00fe96d9f7662cd932d01f',
      ABI,
      this.provider
    )
    window.ethereum
      .request({
        method: 'eth_chainId',
        params: [],
      })
      .then((res) => {
        // console.log('res', res)
        if (parseInt(res, 16) !== 1 && parseInt(res, 16) !== 4) {
          // console.log('切换网络')
          this.walletSwitchEthereumChain()
        }
      })
      .catch((err) => {})
  },
  created() {
    this.initTimer()
    this.LocalAccount = localStorage.getItem('account')
    // console.log('contractAddress', contractAddress)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  watch: {
    account() {
      this.address = this.account
    },
    address() {
      // if (this.address !== this.account || this.address === '') {
      this.isClickMint = false
      this.checkStatus = false
      this.checkNum = ''
      // }
    },
  },
  computed: {
    mintText() {
      if (this.checkNum !== '') {
        return this.messages.mint + '(' + this.checkNum + ')'
      } else {
        return this.messages.mint
      }
    },
    placeholderText() {
      if (this.isShowMint) {
        return 'Enter your address to check if you are qualified'
      } else {
        return 'Enter your tokenId'
      }
    },
    tips() {
      if (this.isShowMint && this.isClickMint) {
        return this.messages.mainContent.meelons.rankResult.mintTips
      } else if (!this.isShowMint) {
        return this.messages.mainContent.meelons.rankResult.rankTips
      } else {
        return ''
      }
    },
    warningTips() {
      if (!this.isCorrectAddress && !this.isAlreadyMint) {
        return this.messages.mainContent.meelons.rankResult.checkTips
      } else if (this.isAlreadyMint) {
        return this.messages.mainContent.meelons.rankResult.massageTips
      } else {
        return ''
      }
      // if (this.isAlreadyMint) {
      //   return this.messages.mainContent.meelons.rankResult.massageTips
      // } else {
      //   return ''
      // }
    },
  },
  methods: {
    initTimer() {
      // 获取当前时间戳精确到秒
      let currentTime = Date.parse(new Date()) //1610075969000
      // let endTime = Date.parse('2021-12-18')
      // 这样子获取到的才是本地时区的时间
      let endTime = Date.parse('December 20,2021 18:00:00')
      // console.log('currentTime', currentTime)
      // console.log('endTime', endTime)
      if (endTime - currentTime > 0) {
        this.remainTime = (endTime - currentTime) / 1000
        this.initHMS(this.remainTime)
        this.startTimer(this.remainTime)
      }
    },
    // 计算时分秒
    initHMS(remainTime) {
      if (remainTime <= 59) {
        this.second = remainTime
        this.hours = '00'
        this.mins = '00'
      } else if (remainTime > 59 && remainTime <= 3599) {
        this.hours = '00'
        this.mins = parseInt(remainTime / 60)
        this.second = parseInt(remainTime - this.mins * 60)
      } else if (remainTime > 3599) {
        this.hours = parseInt(remainTime / 3600)
        this.mins = parseInt((remainTime - this.hours * 60 * 60) / 60)
        this.second = parseInt(
          remainTime - this.hours * 60 * 60 - this.mins * 60
        )
      }
      if (this.hours < 10 && this.hours !== '00') {
        this.hours = '0' + this.hours
      }
      if (this.mins < 10 && this.mins !== '00') {
        this.mins = '0' + this.mins
      }
      if (this.second < 10) {
        this.second = '0' + this.second
      }
      // console.log('hours', this.hours)
      // console.log('mins', this.mins)
      // console.log('second', this.second)
    },

    startTimer(remainTime) {
      this.intervalId = setInterval(() => {
        remainTime--
        if (remainTime <= 0) {
          this.remainTime = remainTime
          clearInterval(this.intervalId)
          // console.log(remainTime)
        }
        this.initHMS(remainTime)
      }, 1000)
    },

    walletSwitchEthereumChain() {
      window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        })
        .then((res) => {
          // console.log('res', res)
        })
        .catch((err) => {})
    },
    mintView() {
      this.isShowMint = true
      this.checkRankStatus = false
      this.isTokenId = false
      this.address = this.account
    },
    rankView() {
      this.isShowMint = false
      this.isCorrectAddress = true
      this.isAlreadyMint = false
      this.address = ''
    },
    async check() {
      if (!this.address) return
      this.checkNum = ''
      if (this.isShowMint) {
        // if (this.address !== this.account) {
        //   this.isCorrectAddress = false
        //   // return
        // } else {
        //   this.isCorrectAddress = true
        // }
        try {
          const res = checkWhiteList(this.address)
          console.log('res',res);
          if (res.length > 0) {
            this.proof = []
            this.whichRoot = []
            res.forEach((item) => {
              this.proof.push(item.proof)
              this.whichRoot.push(item.i)
            })
            console.log(this.whichRoot)
            const data = await this.contract.queryMintAllow(
              this.address,
              this.whichRoot
            )
            console.log(data)

            this.whichRoot = this.whichRoot.filter((w, j) => {
              return data[j]
            })
            this.proof = this.proof.filter((p, k) => {
              return data[k]
            })
            data.some((m) => m === true)
              ? (this.isAlreadyMint = false)
              : (this.isAlreadyMint = true)
            this.checkStatus = true
            console.log('proof', this.proof)
            console.log('whichRoot', this.whichRoot)
          }
          this.checkNum = this.whichRoot.length
          // return [null, data]
        } catch (error) {
          // console.log(error)
          this.checkNum = '0'
          return ['query error']
        }
      } else {
        // rank
        let obj = checkRank(Number(this.address))
        if (obj) {
          this.checkRankStatus = true
          obj.meelon = this.address
          obj = this.getRankImg(obj, obj.tokenId)
          this.rankData = obj
        } else {
          this.isTokenId = true
          this.checkRankStatus = false
        }
      }
    },
    getRankImg(obj, tokenId) {
      if (tokenId <= 1000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.one}/${tokenId}.png`
      } else if (tokenId > 1000 && tokenId <= 2000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.two}/${tokenId}.png`
      } else if (tokenId > 2000 && tokenId <= 3000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.three}/${tokenId}.png`
      } else if (tokenId > 3000 && tokenId <= 4000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.four}/${tokenId}.png`
      } else if (tokenId > 4000 && tokenId <= 5000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.five}/${tokenId}.png`
      } else if (tokenId > 5000 && tokenId <= 6000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.six}/${tokenId}.png`
      } else if (tokenId > 6000 && tokenId <= 7000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.seven}/${tokenId}.png`
      } else if (tokenId > 7000 && tokenId <= 8000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.eight}/${tokenId}.png`
      } else if (tokenId > 8000 && tokenId <= 9000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.nine}/${tokenId}.png`
      } else if (tokenId > 9000 && tokenId <= 10000) {
        obj.avatar = `https://gateway.pinata.cloud/ipfs/${MeelonNFT.ten}/${tokenId}.png`
      }
      return obj
    },
    async mint() {
      if (localStorage.getItem('account')) {
        const abiData = buildMint(this.proof, this.whichRoot)
        let gas
        try {
          // let gas = await this.provider.estimateGas({
          gas = await this.provider.estimateGas({
            from: this.account,
            to: '0xdd1c843a0e960724aa00fe96d9f7662cd932d01f',
            data: abiData,
            value: 0,
          })
        } catch (error) {
          // alert('Please connect the wallet with a whitelisted address to mint.')
          // console.log('errrrrr')
          if (this.address !== this.account) {
            this.isCorrectAddress = false
          } else {
            this.isCorrectAddress = true
          }
          return
        }
        const signer = this.provider.getSigner()
        gas = gas.toNumber() + 50000
        const tx = {
          to: '0xdd1c843a0e960724aa00fe96d9f7662cd932d01f',
          data: abiData,
          gasLimit: gas,
        }
        return new Promise((resolve, reject) => {
          signer
            .sendTransaction(tx)
            .then((res) => {
              this.isClickMint = true
              // console.log('hash', res)
              this.checkResultUrl += res.hash
              resolve([null, res])
            })
            .catch((err) => {
              reject([err])
            })
        })
      } else {
        if (
          navigator.userAgent.indexOf('TokenPocket') === -1 &&
          checkClinetModel() !== 'pc'
        ) {
          alert('Please connect to Token Pocket to use')
        }
        try {
          if (window.ethereum) {
            const accounts = await window.ethereum.request({
              method: 'eth_requestAccounts',
            })
            // console.log(accounts)
            this.address = accounts[0]
            this.getPaccount(accounts[0])
            localStorage.setItem('account', this.account)
          }
        } catch (error) {
          // console.error(error)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.input-placeholder {
  font-size: 0.18rem;
  font-family: Poppins, Poppins-Medium;
  font-weight: bold;
  text-align: left;
  color: #9ea0a5;
}
// placeholder的样式
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  .input-placeholder();
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  .input-placeholder();
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  .input-placeholder();
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  .input-placeholder();
}
@media screen and(min-width:767px) {
  .main-content {
    .video-wrap {
      background: rgba(0, 0, 0, 0.8);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      #video {
        display: block;
        width: 100%;
        height: 100%;
      }
      #video-mobile {
        display: none;
      }
      .title-desc {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .title {
          width: 11rem;
          font-size: 1.2rem;
          font-family: Poppins-Bold;
          text-align: center;
          color: #ffffff;
        }
        .desc {
          // width: 9.83rem;
          font-size: 0.2rem;
          font-family: Poppins-Medium;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
          padding: 0.6rem 0.6rem 0 0.6rem;
        }
      }
    }
    .meelons-wrap {
      background-color: rgb(23, 23, 23);
      &-title {
        font-size: 0.48rem;
        font-family: Poppins-bold;
        // font-weight: bold;
        text-align: center;
        color: #ffffff;
        padding-top: 1.63rem;
      }
      &-list {
        width: 12.02rem;
        margin: 0 auto;
        margin-top: 0.72rem;
        display: flex;
        justify-content: space-around;
        &-item {
          width: 3.84rem;
          height: 3.3rem;
          background: #90ff75;
          border-radius: 0.16rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: space-around;
          padding: 0.32rem 0;
          &.bgblue {
            background-color: #2980fe;
          }
          &.bgyellow {
            background-color: #ff9c41;
          }
          img {
            width: 0.52rem;
            height: 0.52rem;
          }
          span {
            padding: 0 0.35rem;
            font-weight: 400;
            margin-top: 0.1rem;
          }
          :nth-child(2) {
            font-size: 0.32rem;
            font-family: Poppins-bold;
            text-align: center;
            color: #141414;
          }
          :nth-child(3) {
            font-size: 0.16rem;
            font-family: Poppins-Medium;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            line-height: 0.22rem;
          }
        }
      }
      &-mint-rank {
        width: 12rem;
        // height: 3.2rem;
        background: #0b0c0d;
        border-radius: 0.16rem;
        margin: 0 auto;
        margin-top: 0.72rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0.4rem 0 0.8rem 0;
        .header {
          width: 4rem;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          span {
            font-size: 0.32rem;
            font-family: Poppins-bold;
            font-weight: bold;
            text-align: center;
            color: #4f4f4f;
            cursor: pointer;
            &.active {
              color: #fff;
            }
          }
        }
        .content {
          height: 0.8rem;
          display: flex;
          justify-content: space-evenly;
          margin-top: 0.48rem;
          padding: 0 0.7rem;
          .input-box {
            height: 0.8rem;
            display: flex;
            input {
              width: 7.1rem;
              background: #ffffff;
              border-radius: 0.08rem;
              padding-left: 0.32rem;
              font-size: 0.18rem;
              font-family: Poppins-Medium;
              text-align: left;
              color: #000000;
            }
          }
          span {
            width: 1.44rem;
            height: 0.8rem;
            background: #2980fe;
            border-radius: 0.08rem;
            font-size: 0.16rem;
            font-family: Poppins-Medium;
            text-align: center;
            color: #ffffff;
            line-height: 0.8rem;
            cursor: pointer;
          }
          :nth-child(3) {
            background-color: #292929;
            color: #828282;
            pointer-events: none;
            &.active {
              background: #2980fe;
              color: #ffffff;
              pointer-events: all;
            }
          }
        }
        .rank-result {
          margin-top: 0.48rem;
          &-box {
            width: 5.62rem;
            background: #ff9c41;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.29rem 0;
            border-radius: 0.08rem;
            img {
              width: 1.44rem;
              height: 1.44rem;
            }
            .detail {
              display: flex;
              flex-direction: column;
              margin-left: 0.57rem;
              span {
                font-size: 0.24rem;
                font-family: Poppins-bold;
                font-weight: bold;
                text-align: left;
                color: #ffffff;
              }
              :nth-child(2) {
                margin-top: 0.17rem;
                margin-bottom: 0.04rem;
              }
              div {
                width: 1.62rem;
                height: 0.45rem;
                background: #000000;
                font-size: 0.23rem;
                font-family: Poppins-bold;
                text-align: center;
                color: #ffffff;
                line-height: 0.45rem;
              }
            }
          }
          &-bottom {
            margin-top: 0.3rem;
            font-size: 0.14rem;
            font-family: Poppins-Medium;
            text-align: center;
            color: #9ea0a5;
          }
        }
        .default-rank {
          width: 5.62rem;
          margin: 0 auto;
          margin-top: 0.48rem;
          height: 2.24rem;
          line-height: 2.24rem;
          border: 2px dotted #3a3b3d;
          border-radius: 0.16rem;
          font-size: 0.16rem;
          font-family: Poppins-Medium;
          text-align: center;
          color: #3a3b3d;
        }
        .tips {
          margin-top: 0.3rem;
          font-size: 0.14rem;
          font-family: Poppins-Medium;
          text-align: center;
          color: #9ea0a5;
        }
        .massage-tips {
          margin-top: 0.2rem;
          color: red;
        }
        a {
          width: 3.5rem;
          margin: 0 auto;
          .protocol {
            margin-top: 0.1rem;
            color: #2f80ed;
          }
        }
        .timer {
          margin-top: 0.48rem;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .circle-content {
            height: 0.68rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              width: 0.1rem;
              height: 0.1rem;
              background: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
// 移动端
@media screen and(max-width:767px) {
  .main-content {
    .video-wrap {
      background: rgba(0, 0, 0, 0.8);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      #video {
        display: none;
      }
      #video-mobile {
        display: block;
        width: 100%;
        height: 9.6rem;
      }
      .title-desc {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .title {
          font-size: 0.64rem;
          // font-family: Poppins-SemiBold;
          font-family: Poppins-Bold;
          text-align: center;
          color: #ffffff;
        }
        .desc {
          margin-top: 0.56rem;
          width: 6.38rem;
          font-size: 0.24rem;
          font-family: Poppins-Medium;
          font-weight: 400;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .meelons-wrap {
      background-color: rgb(23, 23, 23);
      &-title {
        font-size: 0.48rem;
        font-family: Poppins, Poppins-bold;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        padding-top: 1.46rem;
      }
      &-list {
        margin: 0 auto;
        margin-top: 0.72rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-item {
          width: 6.7rem;
          height: 5.5rem;
          background: #90ff75;
          border-radius: 0.16rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          padding: 0.5rem 0.5rem;
          margin-top: 0.24rem;
          &.bgblue {
            background-color: #2980fe;
          }
          &.bgyellow {
            background-color: #ff9c41;
          }
          img {
            width: 0.88rem;
            height: 0.88rem;
          }
          // span {
          //   font-weight: 400;
          //   font-size: 0.32rem;
          // }
          :nth-child(2) {
            font-size: 0.4rem;
            font-family: Poppins, Poppins-bold;
            text-align: center;
            color: #141414;
          }
          :nth-child(3) {
            font-family: Poppins, Poppins-Medium;
            font-size: 0.32rem;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
          }
          // &:nth-of-type(n + 1) {
          //   margin-top: 0.24rem;
          // }
        }
      }
      &-mint-rank {
        width: 6.7rem;
        background: #0b0c0d;
        border-radius: 0.16rem;
        margin: 0 auto;
        margin-top: 0.72rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0.4rem 0 0.8rem 0;

        .header {
          width: 4rem;
          margin: 0 auto;
          margin-top: 0.65rem;
          display: flex;
          justify-content: space-around;
          span {
            font-size: 0.4rem;
            font-family: Poppins, Poppins-bold;
            font-weight: bold;
            text-align: center;
            color: #4f4f4f;
            cursor: pointer;
            &.active {
              color: #fff;
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          padding: 0 0.7rem 0.2rem 0.7rem;
          .input-box {
            height: 0.8rem;
            display: flex;
            margin-top: 0.3rem;
            input {
              width: 5.9rem;
              background: #ffffff;
              border-radius: 0.08rem;
              padding-left: 0.2rem;
              font-size: 0.18rem;
              font-family: Poppins, Poppins-bold;
              font-weight: bold;
              text-align: left;
              color: #000000;
            }
          }
          span {
            width: 1.44rem;
            height: 0.8rem;
            background: #2980fe;
            border-radius: 0.08rem;
            font-size: 0.16rem;
            font-family: Poppins, Poppins-Medium;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 0.8rem;
            cursor: pointer;
            margin-top: 0.5rem;
          }
          :nth-child(3) {
            background-color: #292929;
            color: #828282;
            pointer-events: none;
            &.active {
              background: #2980fe;
              color: #ffffff;
              pointer-events: all;
            }
          }
        }
        .rank-result {
          margin-bottom: 0.5rem;
          &-box {
            width: 5.62rem;
            background: #ff9c41;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.29rem 0;
            border-radius: 0.08rem;
            img {
              width: 1.44rem;
              height: 1.44rem;
            }
            .detail {
              display: flex;
              flex-direction: column;
              margin-left: 0.57rem;
              span {
                font-size: 0.24rem;
                font-family: Poppins, Poppins-bold;
                font-weight: bold;
                text-align: left;
                color: #ffffff;
              }
              :nth-child(2) {
                margin-top: 0.17rem;
                margin-bottom: 0.04rem;
              }
              div {
                width: 1.62rem;
                height: 0.45rem;
                background: #000000;
                font-size: 0.23rem;
                font-family: Poppins, Poppins-bold;
                font-weight: bold;
                text-align: center;
                color: #ffffff;
                line-height: 0.45rem;
              }
            }
          }
          &-bottom {
            margin-top: 0.3rem;
            font-size: 0.14rem;
            font-family: Poppins, Poppins-Medium;
            font-weight: 400;
            text-align: center;
            color: #9ea0a5;
          }
        }
        .default-rank {
          width: 5.62rem;
          margin: 0 auto;
          margin-bottom: 0.5rem;
          height: 2.24rem;
          line-height: 2.24rem;
          border: 2px dotted #3a3b3d;
          border-radius: 0.16rem;
          font-size: 0.16rem;
          font-family: Poppins, Poppins-Medium;
          font-weight: 400;
          text-align: center;
          color: #3a3b3d;
        }
        .tips {
          margin-top: 0.3rem;
          font-size: 0.28rem;
          font-family: Poppins, Poppins-Medium;
          font-weight: 400;
          text-align: center;
          color: #9ea0a5;
        }
        .massage-tips {
          margin-top: 0.2rem;
          color: red;
        }
        .protocol {
          margin-top: 0.1rem;
          color: #2f80ed;
        }
        .timer {
          margin-top: 0.48rem;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .circle-content {
            height: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div {
              width: 0.07rem;
              height: 0.07rem;
              background: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
