<template>
  <div class="attributes">
    <div class="attributes-title">{{ messages.attributes }}</div>
    <div
      class="attributes-listdata"
      v-for="(obj, i) in messages.attributesContent.listData"
      :key="i"
    >
      <div class="header">{{ obj.title }}</div>
      <div class="list" :ref="'list' + i">
        <div class="item" v-for="(item, j) in obj.list" :key="j">
          <div class="item-left">
            <span>{{ item.itemName }}</span>
            <span>{{ item.desc }}</span>
            <div>{{ item.percent }}</div>
          </div>
          <img class="item-right" :src="item.icon" />
        </div>
      </div>
      <img
        :style="{ display: 'none' }"
        :ref="'leftArrow' + i"
        class="arrow arrow-left"
        src="../assets/images/common/attributes/scroll-arrow.png"
        @click="moveScroll(i, false)"
      />
      <img
        class="arrow arrow-right"
        :ref="'rightArrow' + i"
        src="../assets/images/common/attributes/scroll-arrow.png"
        @click="moveScroll(i, true)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Attributes',
  props: {
    messages: {
      type: Object,
    },
  },
  mounted() {
    this.initArrow()
  },
  methods: {
    initArrow() {
      this.messages.attributesContent.listData.forEach((list, index) => {
        // console.log('scrollWidth', this.$refs['list' + index][0].scrollWidth)
        if (this.$refs['list' + index][0].scrollWidth <= 1200) {
          this.$refs['rightArrow' + index][0].style.display = 'none'
        }
      })
    },
    // 滚动条左右移动,匀速移动效果
    moveScroll(index, flag) {
      if (!this.isEnable) {
        this.isEnable = true
        let moveDis = 1000
        let timeMove = 4
        if (!flag) timeMove = -4
        // console.log(this.$refs)
        let count = 0
        let timer = setInterval(() => {
          this.$refs['list' + index][0].scrollLeft += timeMove
          // 判断滚动条是否滚动到底部
          if (
            this.$refs['list' + index][0].scrollWidth <=
            this.$refs['list' + index][0].offsetWidth +
              this.$refs['list' + index][0].scrollLeft
          ) {
            clearInterval(timer)
            this.isEnable = false
            this.$refs['leftArrow' + index][0].style.display = 'block'
            console.log(2222)
          }
          count += 10
          if (count >= moveDis) {
            this.isEnable = false
            clearInterval(timer)
            if (this.$refs['list' + index][0].scrollLeft > 0) {
              this.$refs['leftArrow' + index][0].style.display = 'block'
            } else if (this.$refs['list' + index][0].scrollLeft <= 0) {
              this.$refs['leftArrow' + index][0].style.display = 'none'
            }
          }
        }, 10)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .attributes {
    padding-bottom: 2rem;
    &-title {
      width: 12rem;
      margin: 0 auto;
      text-align: center;
      font-size: 0.48rem;
      font-family: Poppins, Poppins-bold;
      font-weight: bold;
      color: #ffffff;
      padding-top: 1.5rem;
    }
    &-listdata {
      position: relative;
      width: 12rem;
      margin: 0 auto;
      .header {
        font-size: 0.32rem;
        font-family: Poppins, Poppins-bold;
        font-weight: bold;
        text-align: left;
        color: #ffffff;
      }
      .list {
        display: flex;
        margin-top: 0.2rem;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        overflow-x: auto;
        .item {
          width: 2.82rem;
          height: 1.84rem;
          background-color: #000;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0 0.2rem;
          .item-left {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            span:nth-child(1) {
              height: 0.5rem;
              width: 1.1rem;
              font-size: 0.16rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 500;
              text-align: left;
              color: #ffffff;
            }
            span:nth-child(2) {
              font-size: 0.1rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 400;
              text-align: left;
              color: rgba(255, 255, 255, 0.5);
              padding: 0.12rem 0;
            }
            div {
              width: 0.85rem;
              height: 0.32rem;
              line-height: 0.32rem;
              border: 0.01rem dashed #4f4f4f;
              border-radius: 0.04rem;
              font-size: 0.14rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 500;
              text-align: center;
              color: #ffffff;
            }
          }
          .item-right {
            width: 1.34rem;
            height: 1.34rem;
          }
          &:nth-of-type(n + 2) {
            margin-left: 0.1rem;
          }
        }
      }
      .arrow {
        position: absolute;
        top: 1.4rem;
        width: 0.4rem;
        height: 0.4rem;
      }
      .arrow-left {
        left: -0.3rem;
        transform: rotate(180deg);
      }
      .arrow-right {
        right: -0.3rem;
      }
      &:nth-of-type(n + 2) {
        margin-top: 0.5rem;
      }
    }
  }
}
@media screen and(max-width:767px) {
  .attributes {
    padding-bottom: 2rem;
    &-title {
      margin: 0 auto;
      text-align: center;
      font-size: 0.48rem;
      font-family: Poppins, Poppins-bold;
      font-weight: bold;
      color: #ffffff;
      padding-top: 1.5rem;
    }
    &-listdata {
      position: relative;
      margin: 0 auto;
      margin-left: 0.4rem;
      .header {
        font-size: 0.32rem;
        font-family: Poppins, Poppins-bold;
        font-weight: bold;
        text-align: left;
        color: #ffffff;
      }
      .list {
        display: flex;
        margin-top: 0.6rem;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        overflow-x: auto;
        .item {
          height: 2.32rem;
          background-color: #000;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0 0.2rem;
          .item-left {
            height: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            span:nth-child(1) {
              height: 0.5rem;
              width: 1.5rem;
              font-size: 0.22rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 500;
              text-align: left;
              color: #ffffff;
            }
            span:nth-child(2) {
              font-size: 0.26rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 400;
              text-align: left;
              color: rgba(255, 255, 255, 0.5);
              padding: 0.12rem 0;
            }
            div {
              width: 1.18rem;
              height: 0.45rem;
              line-height: 0.45rem;
              border: 0.01rem dashed #4f4f4f;
              border-radius: 0.04rem;
              font-size: 0.26rem;
              font-family: Poppins, Poppins-Medium;
              font-weight: 500;
              text-align: center;
              color: #ffffff;
            }
          }
          .item-right {
            width: 1.86rem;
            height: 1.86rem;
          }
          &:nth-of-type(n + 2) {
            margin-left: 0.1rem;
          }
        }
      }
      .arrow {
        display: none;
      }
      &:nth-of-type(n + 2) {
        margin-top: 1.18rem;
      }
    }
  }
}
</style>
