<template>
  <div class="mobile-popup">
    <div class="mobile-popup-wrap">
      <span>Connect wallet</span>
      <span>Please open in your wallet</span>
      <div class="close-popup" @click="close">OK</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mobile-popup',
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less">
.mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem;
  &-wrap {
    width: 8rem;
    // height: 3.72rem;
    background: #1e1f24;
    border-radius: 0.1rem;
    box-shadow: 0 0.8rem 0.08rem -0.5rem rgba(31, 47, 70, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    :nth-child(1) {
      font-size: 0.68rem;
      font-family: Poppins-Meduim;
      font-weight: 400;
      text-align: center;
      color: #fcfcfd;
    }
    :nth-child(2) {
      margin-top: 0.2rem;
      font-size: 0.54rem;
      font-family: DM Sans, DM Sans-Bold;
      font-weight: 700;
      text-align: center;
      color: #7d7d8b;
    }
    div {
      margin-top: 0.2rem;
      width: 5.28rem;
      height: 0.88rem;
      line-height: 0.88rem;
      background: #2980fe;
      border-radius: 0.14rem;
      font-size: 0.32rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
