<template>
  <div class="timer-box">
    <div class="timer-box-header"></div>
    <div class="timer-box-content">{{ remainTime }}</div>
    <div class="timer-box-footer">{{ time }}</div>
  </div>
</template>

<script>
export default {
  name: 'TimerBox',
  props: {
    time: {
      type: String,
    },
    remainTime: {
      type: String,
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .timer-box {
    position: relative;
    width: 2.64rem;
    height: 2.54rem;
    div {
      border-radius: 0.08rem;
      font-family: Poppins, Poppins-SemiBold;
      font-weight: bold;
      text-align: center;
    }
    &-header {
      height: 0.09rem;
      background: #2980fe;
    }
    &-content {
      height: 2.02rem;
      background: #181818;
      font-size: 0.96rem;
      color: #ffffff;
      line-height: 2.02rem;
    }
    &-footer {
      position: absolute;
      bottom: 0;
      width: 2.64rem;
      height: 0.6rem;
      background: #272727;
      font-size: 0.24rem;
      color: rgba(255, 255, 255, 0.32);
      line-height: 0.6rem;
    }
  }
}
@media screen and(max-width:767px) {
  .timer-box {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    div {
      border-radius: 0.08rem;
      font-family: Poppins, Poppins-SemiBold;
      font-weight: bold;
      text-align: center;
    }
    &-header {
      height: 0.09rem;
      background: #2980fe;
    }
    &-content {
      height: 1.2rem;
      background: #181818;
      font-size: 0.65rem;
      color: #ffffff;
      line-height: 1.2rem;
    }
    &-footer {
      position: absolute;
      bottom: 0;
      width: 1.5rem;
      height: 0.3rem;
      background: #272727;
      font-size: 0.16rem;
      color: rgba(255, 255, 255, 0.32);
      line-height: 0.3rem;
    }
  }
}
</style>
