//npm install merkletreejs
const { MerkleTree } = require('merkletreejs')
//npm install keccak256
const keccak256 = require('keccak256')
//npm install web3
const Web3 = require('web3')
//npm install ethers
const { ethers } = require('ethers')
// 测试地址
var TEST = [
  '0x1F99c6F06210dfb374450383B1423344DdF77556',
  '0x5De6cafc9eC8B419AFA290b84F191A00e0E23cD2',
  '0x959E1d86982BDD541cBB8a36bd74693249f088f2',
  '0x9D88fdb92EB390DAaE4fE0A6a80E0e4e47a68B22',
  '0x5F75DDA1753f2dd5AF909b0f0471f751eb47fd52',
  '0xA1C390E72b0c7371fd1dFA2ba13E4CF960a60B8A',
  '0xEeb3e373609f5912C357Eab2b80a2F66E58b8940',
  '0x09C71171549510843DA40F90343D2007ffA5917f',
  '0x22F4900A1fB41f751b8F616832643224606B75B4',
]
//白名单地址
var AirdropLIST = require('../address/AirdropLIST.json')
var DAOLIST = require('../address/DAOLIST.json')
var KeyPalLIST = require('../address/KeyPalLIST.json')
var NFTGOLIST = require('../address/NFTGOLIST.json')
var NFTSCANLIST = require('../address/NFTSCANLIST.json')
var SOMELIST = require('../address/SOMELIST.json')
var TransitSwapLIST = require('../address/TransitSwapLIST.json')
var TwitterLIST = require('../address/TwitterLIST.json')
var LIST1 = require('../address/LIST1.json')
var LIST2 = require('../address/LIST2.json')
var LIST3 = require('../address/LIST3.json')
var LIST4 = require('../address/LIST4.json')
var LIST5 = require('../address/LIST5.json')
var LIST6 = require('../address/LIST6.json')
var LIST51 = require('../address/List51.json')
var LIST52 = require('../address/List52.json')
var whiteList = [
  TwitterLIST,
  DAOLIST,
  TransitSwapLIST,
  AirdropLIST,
  KeyPalLIST,
  SOMELIST,
  NFTSCANLIST,
  NFTGOLIST,
  LIST1,
  LIST2,
  LIST3,
  LIST4,
  LIST5,
  LIST6,
  LIST51,
  LIST52
]

const whichListMap = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
  7: 8,
  8: 9,
  9: 10,
  10: 11,
  11: 12,
  12: 13,
  13: 14,
  14: 51,
  15: 52,
}


// var whiteList = require('../address/address.json')
var rankSortList = require('../address/RankSort.json')

var web3 = new Web3(new Web3.providers.HttpProvider(''))

// 测试合约地址
// 0xb2810d7f7dcc1f3bc0c63bbb3817c5f869b03abe
// 合约地址
// 0xdd1c843a0e960724aa00fe96d9f7662cd932d01f
export const contractAddress = '0xdd1c843a0e960724aa00fe96d9f7662cd932d01f'
// 创建账户
export function createAddress(number) {
  var addresses = []
  for (var i = 0; i < number - 1; i++) {
    var res = web3.eth.accounts.create()
    addresses.push(res['address'])
  }
  return addresses
}

export function merkleTree(leaves) {
  leaves = leaves.map((v) => keccak256(v))
  var tree = new MerkleTree(leaves, keccak256, { sort: true })
  var root = tree.getHexRoot()
  return [tree, root]
}

//校验是否在白名单中
export function checkWhiteList(myAddress) {
  let proofList = []
  for (var i = 0; i < whiteList.length; i++) {
    var tree = merkleTree(whiteList[i])
    var leaf = keccak256(myAddress)
    var proof = tree[0].getHexProof(leaf)
    if (proof.length >= 1) {
      //todo 是白名单的逻辑
      proofList.push({ proof, i: whichListMap[i] })
    }
  }
  return proofList
}

// 查排名
export function checkRank(tokenId) {
  // console.log('tokenId', typeof tokenId)
  const arr = rankSortList.filter((item) => item.tokenId == tokenId)
  return arr[0]
}

//打包合约交易

const ABI = [
  'function mintMeelon(bytes32[][] memory proof, uint[] memory whichRoot) public returns (uint256 tokenId)',
  'function queryMintAllow(address account, uint[] memory whichList) public view returns (bool[] memory result)',
]
const iface = new ethers.utils.Interface(ABI)

//proof 见 上面👆校验白名单的操作，组成二维数组
//这个build好的数据就是交易使用的data
export function buildMint(proof, whichRoot) {
  return iface.encodeFunctionData('mintMeelon', [proof, whichRoot])
}

// 查询是否mint
export function checkIsMint(account, whichList) {
  return iface.encodeFunctionData('queryMintAllow', [account, whichList])
}

// window.ethereum.on('accountsChanged', (accounts) => {})

// var provider = new ethers.providers.Web3Provider(window.ethereum)
// var contract = new ethers.Contract(
//   '0xb2810d7f7dcc1f3bc0c63bbb3817c5f869b03abe',
//   ABI,
//   provider,
// )
// try {
//   var data = await contract.queryFarmData()
//   return [null, data]
// } catch (error) {
//   console.log(error)
//   return ['query error']
// }

// let gas = await provider.estimateGas({
//   from: accounts,
//   to: token,
//   data: abiData,
//   value: 0,
// })
// const signer = provider.getSigner()
// gas = gas.toNumber() + 50000
// const tx = {
//   to: token,
//   data: abiData,
//   gasLimit: gas,
// }
// return new Promise((resolve, reject) => {
//   signer
//     .sendTransaction(tx)
//     .then((res) => {
//       resolve([null, res])
//     })
//     .catch((err) => {
//       reject([err])
//     })
// })
