<template>
  <div class="header-navbar">
    <!-- <img src="../assets/images/common/meelons.png" /> -->
    <img :src="logoImg" />
    <div class="mint navbar-text" @click="navBarGoMint">
      {{ messages.mint }}
    </div>
    <div class="rarities navbar-text" @click="navBarGoRaritties">
      {{ messages.rarities }}
    </div>
    <div class="rarities navbar-text" @click="navBarGoOpensea">
      {{ messages.viewInOpensea }}
    </div>
    <div class="connect-wallet navbar-text" @click="showWalletList">
      {{ account }}
    </div>
    <ChooseWallet
      v-if="isConnect"
      @chooseWallet="chooseWallet"
      @close="close"
    />
    <MobilePopup v-if="isMobilePopup" @close="close" />
  </div>
</template>

<script>
import { checkClinetModel } from '../utils'
import ChooseWallet from './ChooseWallet.vue'
import MobilePopup from './MobilePopup.vue'

export default {
  name: 'HeaderNavBar',
  components: {
    ChooseWallet,
    MobilePopup,
  },
  props: {
    messages: {
      type: Object,
    },
    goViewPosition: {
      type: Function,
    },
    getAccount: {
      type: Function,
    },
    pAccount: {
      type: String,
    },
  },
  data() {
    return {
      account: 'Connect wallet',
      logoImg: require('../assets/images/common/meelons.png'),
      isConnect: false,
      isMobilePopup: false,
    }
  },
  created() {
    // if (localStorage.getItem('account')) {
    //   this.account = localStorage.getItem('account')
    // }
    window.ethereum.on('accountsChanged', this.onAccountsChanged)
  },
  watch: {
    pAccount() {
      this.account = this.pAccount.slice(0, 6) + '...' + this.pAccount.slice(-6)
    },
  },

  methods: {
    navBarGoMint() {
      this.goViewPosition('.meelons-wrap-mint-rank')
    },
    navBarGoRaritties() {
      this.goViewPosition('.rarities-title')
    },
    navBarGoOpensea() {
      window.open('https://opensea.io/collection/transit-nft-meelons')
    },
    // 监听切换账号
    onAccountsChanged(accounts) {
      // console.log('accounts', accounts)
      this.account = accounts[0].slice(0, 6) + '...' + accounts[0].slice(-6)
      this.getAccount(accounts[0])
    },
    // 连接钱包
    showWalletList() {
      this.isConnect = true

      // if (
      //   navigator.userAgent.indexOf('TokenPocket') === -1 &&
      //   checkClinetModel() !== 'pc'
      // ) {
      //   this.isMobilePopup = true
      // } else {
      //   this.isConnect = true
      // }
    },
    async connectWallet() {
      if (
        navigator.userAgent.indexOf('TokenPocket') === -1 &&
        checkClinetModel() !== 'pc'
      ) {
        alert('Please connect to Token Pocket to use')
      }
      try {
        if (window.ethereum) {
          const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          })
          this.account = accounts[0].slice(0, 6) + '...' + accounts[0].slice(-6)
          localStorage.setItem('account', this.account)
          this.getAccount(accounts[0])
        }
      } catch (error) {
        // console.error(error)
      }
    },
    chooseWallet() {
      this.isConnect = false
      this.connectWallet()
    },
    close() {
      this.isConnect = false
      this.isMobilePopup = false
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width: 767px) {
  .header-navbar {
    // width: 14.4rem;
    height: 1.06rem;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    .navbar-text {
      font-size: 0.16rem;
      font-family: Poppins-Bold;
      // font-weight: bold;
      text-align: center;
      color: #ffffff;
      letter-spacing: 0px;
      cursor: pointer;
    }
    img {
      // width: 2.03rem;
      height: 0.34rem;
      // margin-left: 1.49rem;
    }
    .mint {
      margin-left: 1.14rem;
    }
    .rarities {
      margin-left: 0.52rem;
    }
    .connect-wallet {
      margin-left: 4.52rem;
      width: 2.2rem;
      height: 0.52rem;
      line-height: 0.52rem;
      border: 0.01rem solid #ffffff;
      box-shadow: 0 0.04rem 0.31rem 0 rgba(0, 0, 0, 0.15);
      text-shadow: 0 0.04rem 0.31rem 0 rgba(0, 0, 0, 0.15);
    }
  }
}
@media screen and(max-width:767px) {
  .header-navbar {
    height: 1.06rem;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 0.32rem;
    padding-left: 0.32rem;
    padding-right: 0.52rem;
    img {
      // width: 2.21rem;
      height: 0.44rem;
    }
    .navbar-text {
      font-size: 0.24rem;
      font-family: Poppins, Poppins-bold;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      letter-spacing: 0px;
      cursor: pointer;
    }
    .mint {
      display: none;
    }
    .rarities {
      display: none;
    }
    .connect-wallet {
      width: 2.83rem;
      height: 0.64rem;
      line-height: 0.64rem;
      border: 0.02rem solid #ffffff;
      box-shadow: 0 0.04rem 0.31rem 0 rgba(0, 0, 0, 0.15);
      text-shadow: 0 0.04rem 0.31rem 0 rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
