<template>
  <div>
    <!-- <HeaderNavBar />
    <MainContent />
    <FooterBottom /> -->
    <HeaderNavBar
      :messages="messages"
      :goViewPosition="goViewPosition"
      :pAccount="pAccount"
      :getAccount="getAccount"
    />
    <MainContent
      :messages="messages"
      :account="account"
      :getPaccount="getPaccount"
    />
    <FooterBottom />
  </div>
</template>

<script>
import FooterBottom from '../components/FooterBottom.vue'
import HeaderNavBar from '../components/HeaderNavBar.vue'
import MainContent from '../components/MainContent.vue'
import messages from '../lang/en/messages'

export default {
  name: 'Home',
  components: {
    HeaderNavBar,
    MainContent,
    FooterBottom,
  },
  data() {
    return {
      messages: messages,
      account: '',
      pAccount: '',
    }
  },
  methods: {
    goViewPosition(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'center', // 居中
      })
    },
    getAccount(account) {
      // console.log('account', account)
      this.account = account
    },
    getPaccount(pAccount) {
      // console.log('getPaccount', pAccount)
      this.pAccount = pAccount
    },
  },
}
</script>

<style></style>
