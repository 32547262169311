<template>
  <div id="app">
    <!-- <HeaderNavBar :messages="messages" :goViewPosition="goViewPosition" />
    <MainContent :messages="messages" />
    <FooterBottom :messages="messages" /> -->
    <router-view />
  </div>
</template>

<script>
// import HeaderNavBar from './components/HeaderNavBar.vue'
// import MainContent from './components/MainContent.vue'
// import FooterBottom from './components/FooterBottom.vue'

import messages from './lang/en/messages'

export default {
  name: 'App',
  // components: {
  //   HeaderNavBar,
  //   MainContent,
  //   FooterBottom,
  // },
  data() {
    return {
      messages: messages,
    }
  },
  methods: {
    goViewPosition(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'center', // 居中
      })
    },
  },
}
</script>
<style lang="less" scoped>
#app {
  .main-container {
    width: 14.4rem;
    margin: 0 auto;
  }
}
</style>
