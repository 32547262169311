<template>
  <div class="choose-wallet">
    <div class="wallet-list">
      <div class="list">
        <span>Connect to a wallet</span>
        <img src="../assets/images/common/close.png" @click="close" />
      </div>
      <div
        class="list list-wallet"
        v-for="(item, index) in walletImgList"
        :key="index"
        @click="onClick"
      >
        <img :src="item.url" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      walletImgList: [
        {
          name: 'dfox',
          url: require('../assets/images/common/dfox.png'),
        },
        {
          name: 'TOKENPOCKET',
          url: require('../assets/images/common/tp.png'),
        },
        {
          name: 'Metamask',
          url: require('../assets/images/common/fox.png'),
        },
      ],
    }
  },
  methods: {
    onClick() {
      this.$emit('chooseWallet')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.choose-wallet {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .wallet-list {
    width: 4.48rem;
    height: 3.92rem;
    background: #1e1f24;
    border-radius: 0.08rem;
    box-shadow: 0 0.64rem 0.634rem -0.48rem rgba(31, 47, 70, 0.12);
    display: flex;
    padding: 0.48rem 0.32rem;
    flex-direction: column;
    justify-content: space-between;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 0.24rem;
        font-family: DM Sans, DM Sans-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #fcfcfd;
        letter-spacing: 0;
      }
      img {
        width: 0.4rem;
        height: 0.4rem;
      }
    }
    .list-wallet {
      justify-content: flex-start;
      width: 3.84rem;
      height: 0.64rem;
      background: #36373d;
      border-radius: 0.11rem;
      cursor: pointer;
      img {
        margin-left: 0.24rem;
      }
      span {
        margin-left: 0.15rem;
      }
    }
  }
}
</style>
