export default {
  mint: 'Mint',
  rarities: 'Rarities',
  viewInOpensea: 'View in OpenSea',
  connectWallet: 'Connect Wallet',
  rank: 'Rank',
  check: 'Check',
  attributes: 'Attributes',

  mainContent: {
    video: {
      title: "It's time to meet the Meelons",
      desc: 'The 10,000 unique NFT Meelon avatars are free to be minted by wallets that participated in the TPT DAO, TPT Donation, ConstitutionDAO, CityDAO, Eth2.0 Staking Vaults, Twitter events, and KeyPal users. The mint event will be conducted on a first-come, first-served basis.',
    },
    meelons: {
      title: "What's the Meelons?",
      list: [
        {
          icon: require('../../assets/images/common/icon1.png'),
          title: 'Chameleon',
          desc: 'The design of Meelon is inspired by the chameleon, a smart, cute and naughty little guy. It is a 2D design that can be used as an avatar, character identity, etc.',
        },
        {
          icon: require('../../assets/images/common/icon2.png'),
          title: 'ERC-721',
          desc: 'The Meelon is an NFT distributed based on the ERC-721 protocol. It can be freely transferred and circulated like other NFTs. It is free to mint in the initial stage.',
        },
        {
          icon: require('../../assets/images/common/icon3.png'),
          title: 'Who can mint?',
          desc: 'Inspired and encouraged by the decentralized community, the Meelons are mintable for wallets that participated TPT DAO, TPT Donation, ConstitutionDAO, CityDAO, Eth2.0 Staking Vaults, Twitter events, etc.',
        },
      ],
      rankResult: {
        meelon: 'Meelon ',
        rank: 'rank',
        noShowNft: 'No show nft',
        massageTips: 'You have already mint this address',
        mintTips: 'Your transaction has been sent,',
        // view the transaction in details
        rankTips: 'To view NFT details , please use TokenPocket',
        protocol: 'The Meelons NFTs follow the CC0 protocol >',
        tokenIdTips: 'Please enter the correct token id',
        checkTips:
          'Please connect the wallet with a whitelisted address to mint.',
      },
      timer: {
        hours: 'HOURS',
        mins: 'MINS',
        second: 'SECOND',
      },
    },
  },
  raritiesContent: {
    desc: 'The combination of features each Meelon has builds its total rarity. Each Meelon NFT is programmatically and randomly generated from over 20 attributes with different probabilities.',
    list: [
      require('../../assets/images/common/rarities/rarities-1.png'),
      require('../../assets/images/common/rarities/rarities-1.png'),
      require('../../assets/images/common/rarities/rarities-1.png'),
      require('../../assets/images/common/rarities/rarities-1.png'),
      require('../../assets/images/common/rarities/rarities-5.png'),
      require('../../assets/images/common/rarities/rarities-6.png'),
      require('../../assets/images/common/rarities/rarities-7.png'),
      require('../../assets/images/common/rarities/rarities-8.png'),
      require('../../assets/images/common/rarities/rarities-9.png'),
      require('../../assets/images/common/rarities/rarities-10.png'),
      require('../../assets/images/common/rarities/rarities-11.png'),
      require('../../assets/images/common/rarities/rarities-12.png'),
    ],
  },
  attributesContent: {
    listData: [
      {
        title: 'Mouth',
        list: [
          {
            itemName: 'Normal',
            desc: 'Appearance',
            percent: '30%',
            icon: require('../../assets/images/common/attributes/mouth/1.png'),
          },
          {
            itemName: 'Hungry',
            desc: 'Appearance',
            percent: '15%',
            icon: require('../../assets/images/common/attributes/mouth/2.png'),
          },
          {
            itemName: 'Sewed Fierce',
            desc: 'Appearance',
            percent: '20%',
            icon: require('../../assets/images/common/attributes/mouth/3.png'),
          },
          {
            itemName: 'whistle',
            desc: 'Appearance',
            percent: '15%',
            icon: require('../../assets/images/common/attributes/mouth/4.png'),
          },
          {
            itemName: 'gagtooth',
            desc: 'Appearance',
            percent: '20%',
            icon: require('../../assets/images/common/attributes/mouth/5.png'),
          },
        ],
      },
      {
        title: 'Necklace',
        list: [
          {
            itemName: 'Cuban Link Chain',
            desc: 'Appearance',
            percent: '10%',
            icon: require('../../assets/images/common/attributes/necklace/1.png'),
          },
          {
            itemName: 'Dollar Link Chain',
            desc: 'Appearance',
            percent: '8%',
            icon: require('../../assets/images/common/attributes/necklace/2.png'),
          },
          {
            itemName: 'Fashion Link Chain',
            desc: 'Appearance',
            percent: '2%',
            icon: require('../../assets/images/common/attributes/necklace/3.png'),
          },
        ],
      },
      {
        title: 'Glasses',
        list: [
          {
            itemName: 'Laser glasses',
            desc: 'Appearance',
            percent: '15%',
            icon: require('../../assets/images/common/attributes/glasses/1.png'),
          },
          {
            itemName: 'VR glasses',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/glasses/2.png'),
          },
          {
            itemName: 'Retro glasses',
            desc: 'Appearance',
            percent: '10%',
            icon: require('../../assets/images/common/attributes/glasses/3.png'),
          },
          // {
          //   itemName: 'Chewing gum',
          //   desc: 'Appearance',
          //   percent: '14%',
          //   icon: require('../../assets/images/common/attributes/glasses/4.png'),
          // },
        ],
      },
      {
        title: 'Eyes',
        list: [
          {
            itemName: 'Beaten',
            desc: 'Appearance',
            percent: '10%',
            icon: require('../../assets/images/common/attributes/eyes/1.png'),
          },
          {
            itemName: 'Bandaged',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/2.png'),
          },
          {
            itemName: 'Sewed eye',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/3.png'),
          },
          {
            itemName: 'lightning Eyes',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/4.png'),
          },
          {
            itemName: 'Shocked',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/5.png'),
          },
          {
            itemName: 'Mystery',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/6.png'),
          },
          {
            itemName: 'Terrified',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/eyes/7.png'),
          },
          {
            itemName: 'Naughty',
            desc: 'Appearance',
            percent: '10%',
            icon: require('../../assets/images/common/attributes/eyes/8.png'),
          },
          {
            itemName: 'Cry',
            desc: 'Appearance',
            percent: '15%',
            icon: require('../../assets/images/common/attributes/eyes/9.png'),
          },
          {
            itemName: 'Bored',
            desc: 'Appearance',
            percent: '25%',
            icon: require('../../assets/images/common/attributes/eyes/10.png'),
          },
          {
            itemName: 'Surprise',
            desc: 'Appearance',
            percent: '10%',
            icon: require('../../assets/images/common/attributes/eyes/11.png'),
          },
        ],
      },
      {
        title: 'Headwear',
        list: [
          {
            itemName: 'Straw hat',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/headwear/1.png'),
          },
          {
            itemName: 'Crown',
            desc: 'Appearance',
            percent: '1%',
            icon: require('../../assets/images/common/attributes/headwear/2.png'),
          },
          {
            itemName: 'Baseball Cap',
            desc: 'Appearance',
            percent: '3%',
            icon: require('../../assets/images/common/attributes/headwear/3.png'),
          },
          {
            itemName: 'Hippop Cap',
            desc: 'Appearance',
            percent: '3%',
            icon: require('../../assets/images/common/attributes/headwear/4.png'),
          },
          {
            itemName: 'Yellow Helmet',
            desc: 'Appearance',
            percent: '3%',
            icon: require('../../assets/images/common/attributes/headwear/5.png'),
          },
          {
            itemName: 'White Helmet',
            desc: 'Appearance',
            percent: '3%',
            icon: require('../../assets/images/common/attributes/headwear/6.png'),
          },
          {
            itemName: 'Blue Catty Headphone',
            desc: 'Appearance',
            percent: '6%',
            icon: require('../../assets/images/common/attributes/headwear/7.png'),
          },
          {
            itemName: 'Pink Catty Headphone',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/headwear/8.png'),
          },
          {
            itemName: 'Burger Catty Headphone',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/headwear/9.png'),
          },
          {
            itemName: 'Fries Catty Headphone',
            desc: 'Appearance',
            percent: '5%',
            icon: require('../../assets/images/common/attributes/headwear/10.png'),
          },
          {
            itemName: 'Hamburger Headphone',
            desc: 'Appearance',
            percent: '6%',
            icon: require('../../assets/images/common/attributes/headwear/11.png'),
          },
          {
            itemName: 'Pink Headphone',
            desc: 'Appearance',
            percent: '6%',
            icon: require('../../assets/images/common/attributes/headwear/12.png'),
          },
        ],
      },

      {
        title: 'Clothes',
        list: [
          {
            itemName: 'Laker T-shirt',
            desc: 'Appearance',
            percent: '3%',
            icon: require('../../assets/images/common/attributes/clothes/1.png'),
          },
          {
            itemName: 'Laker White T-shirt',
            desc: 'Appearance',
            percent: '7%',
            icon: require('../../assets/images/common/attributes/clothes/2.png'),
          },
          {
            itemName: 'Celtics T-shirt',
            desc: 'Appearance',
            percent: '7%',
            icon: require('../../assets/images/common/attributes/clothes/3.png'),
          },
          {
            itemName: 'Bulls T-shirt',
            desc: 'Appearance',
            percent: '7%',
            icon: require('../../assets/images/common/attributes/clothes/4.png'),
          },
          {
            itemName: 'Heat T-shirt',
            desc: 'Appearance',
            percent: '7%',
            icon: require('../../assets/images/common/attributes/clothes/5.png'),
          },
          {
            itemName: 'T-shirt',
            desc: 'Appearance',
            percent: '20%',
            icon: require('../../assets/images/common/attributes/clothes/6.png'),
          },
        ],
      },
    ],
  },
}
