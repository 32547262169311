<template>
  <div class="footer-bottom">
    <div class="footer-bottom-content">
      <div class="logo-copyright">
        <img class="logo" :src="logoImg" />
        <span class="copyright">Transit Finance Copyright © 2021</span>
      </div>
      <div class="link-icons">
        <a :href="item.url" v-for="(item, index) in linkIcons" :key="index">
          <img class="icon" :src="item.icon" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBottom',
  data() {
    return {
      logoImg: require('../assets/images/common/meelons.png'),
      linkIcons: [
        {
          url: 'https://www.transit.finance/en/#/',
          icon: require('../assets/images/common/footer/link-1.png'),
        },
        {
          url: 'https://twitter.com/TransitFinance',
          icon: require('../assets/images/common/footer/link-2.png'),
        },
        {
          url: 'https://fans.tokenpocket.pro/c/transit-swap-announcement-transit-swap/14',
          icon: require('../assets/images/common/footer/link-3.png'),
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 767px) {
  .footer-bottom {
    height: 1.84rem;
    background: #000000;
    &-content {
      width: 12rem;
      height: 1.84rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
          width: 1.69rem;
          height: 0.34rem;
        }
        .copyright {
          margin-top: 0.2rem;
          font-size: 0.14rem;
          font-family: Poppins, Poppins-Regular;
          font-weight: 400;
          text-align: center;
          color: #3a3b3d;
        }
      }
      .link-icons {
        display: flex;
        a {
          width: 0.32rem;
          height: 0.32rem;
          display: flex;
          img {
            width: 0.32rem;
            height: 0.32rem;
          }
          &:nth-of-type(n + 2) {
            margin-left: 0.24rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-bottom {
    height: 1.84rem;
    background: #000000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-content {
      width: 100vw;
      padding: 0 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
          width: 2.2rem;
          height: 0.44rem;
        }
        .copyright {
          margin-top: 0.2rem;
          font-size: 0.14rem;
          font-family: Poppins, Poppins-Regular;
          font-weight: 400;
          text-align: center;
          color: #3a3b3d;
          // transform: scale(0.6);
        }
      }
      .link-icons {
        display: flex;
        img {
          width: 0.48rem;
          height: 0.48rem;
          &:nth-of-type(n + 1) {
            margin-left: 0.36em;
          }
        }
      }
    }
  }
}
</style>
