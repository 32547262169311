import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

let defaultLangStr = navigator.language
let defaultPath = '/en/'

if (defaultLangStr.indexOf('zh') >= 0) {
  defaultPath = '/zh/'
}

const routes = [
  // {
  //   path: '/home',
  //   redirect: {
  //     path: '/home',
  //   },
  // },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'NFT',
    },
    component: Home,
  },
  // {
  //   path: '/home',
  //   name: 'home/Home',
  //   meta: {
  //     title: 'NFT',
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/home/Home.vue'),
  // },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
})

export default router
