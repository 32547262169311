<template>
  <div class="rarities">
    <div class="rarities-title">{{ messages.rarities }}</div>
    <div class="rarities-desc">{{ messages.raritiesContent.desc }}</div>
    <div class="rarities-box">
      <img
        v-for="(url, index) in mobileRaritiesContentList"
        :key="index"
        :src="url"
      />
    </div>
    <a href="https://opensea.io/collection/transit-nft-meelons">
      <div class="view-in-opensea">
        <span>{{ messages.viewInOpensea }}</span>
        <img src="../assets/images/common/arrow.png" />
      </div>
    </a>
  </div>
</template>

<script>
import { checkClinetModel } from '../utils/index'

export default {
  name: 'Rarities',
  props: {
    messages: {
      type: Object,
    },
  },
  computed: {
    mobileRaritiesContentList() {
      if (checkClinetModel() === 'pc') {
        return this.messages.raritiesContent.list
      } else {
        return [
          ...this.messages.raritiesContent.list.slice(0, 2),
          ...this.messages.raritiesContent.list.slice(4, 8),
        ]
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and(min-width:767px) {
  .rarities {
    margin-top: 2rem;
    background-color: #000;
    padding: 2.25rem 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    &-title {
      width: 10rem;
      margin: 0 auto;
      height: 0.72rem;
      font-size: 0.48rem;
      font-family: Poppins, Poppins-bold;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      line-height: 0.72rem;
    }
    &-desc {
      width: 10rem;
      margin: 0 auto;
      margin-top: 0.3rem;
      font-size: 0.16rem;
      font-family: Poppins, Poppins-Medium;
      font-weight: 400;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      // line-height: 0.48rem;
    }
    &-box {
      width: 10rem;
      margin: 0 auto;
      margin-top: 0.6rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      img {
        width: 2.24rem;
        height: 2.24rem;
        border-radius: 0.08rem;
        &:nth-of-type(n + 5) {
          margin-top: 0.32rem;
        }
      }
    }
    .view-in-opensea {
      display: none;
    }
  }
}
@media screen and(max-width:767px) {
  .rarities {
    margin-top: 1.2rem;
    background-color: #000;
    padding: 1.44rem 0 1.25rem 0;
    &-title {
      height: 0.72rem;
      font-size: 0.48rem;
      font-family: Poppins, Poppins-bold;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      line-height: 0.72rem;
    }
    &-desc {
      margin-top: 0.3rem;
      padding: 0 0.6rem;
      font-size: 0.24rem;
      font-family: Poppins, Poppins-Medium;
      font-weight: 400;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
    }
    &-box {
      // width: 10rem;
      margin: 0 auto;
      margin-top: 0.6rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      img {
        width: 3.23rem;
        height: 3.23rem;
        border-radius: 0.08rem;
        &:nth-of-type(n + 3) {
          margin-top: 0.41rem;
        }
      }
    }
    .view-in-opensea {
      width: 4rem;
      margin: 0 auto;
      margin-top: 1.2rem;
      height: 0.8rem;
      background: #2980fe;
      border-radius: 0.08rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 0.3rem;
        font-family: Poppins, Poppins-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
      }
      img {
        margin-left: 0.2rem;
        width: 0.24rem;
        height: 0.24rem;
      }
    }
  }
}
</style>
